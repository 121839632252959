import React from 'react';
import './App.css';

// Display

function Display(props) {
    return (
        <div className="displayBox">
            <h1>{props.value}</h1>
        </div>
    );
}

export default Display;
