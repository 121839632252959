function Description() {
    return (
        <div className="app-description">
            <p>
                This project marks my first experience building a React application, completed as an exercise in 2020 to
                learn the core principles of React. The app features a simple counter that increments the value by 2, 4,
                or 6. While the functionality is basic, the focus of this project was on understanding and applying
                React concepts rather than building complex features. The app is structured with the main App.js
                container and four presentation containers, demonstrating React's component-based architecture.
            </p>
        </div>
    )
}

export default Description;