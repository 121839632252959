import React from 'react';
import './App.css';

function Button(props) {
    const handleClick = () => props.incrementValue(props.incrementBy);
    return (
        <button className="btnTest"
                onClick={handleClick}>
            {props.incrementBy}
        </button>
    );
}

export default Button;
