import React from 'react';
import './App.css';
import Header from "./Header";
import Button from "./Button";
import Display from "./Display";
import ResetButton from "./ResetButton";
import Description from "./Description";

function App() {
    const [counter, setCounter] = React.useState(0);
    const incrementCounter = (val) => {
        setCounter(counter + val);
    };
    const resetCounter = () => {
        setCounter(0);
    }

    return (
        <div>
            <Header/>
            <Description/>
            <div className="buttons-container">
                <Button counter={counter} incrementValue={incrementCounter} incrementBy={2}/>
                <Button counter={counter} incrementValue={incrementCounter} incrementBy={4}/>
                <Button counter={counter} incrementValue={incrementCounter} incrementBy={6}/>
                <ResetButton counter={counter} resetCounter={resetCounter}/>
            </div>
            <Display value={counter}/>
        </div>
    );
}

export default App;
