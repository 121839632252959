import React from 'react';
import './App.css';

function ResetButton(props) {
    const handleClick = () => props.resetCounter();
    return (
        <button className="btnReset"
                onClick={handleClick}>
            Reset
        </button>
    );
}

export default ResetButton;
